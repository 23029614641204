import { Camera } from '@hakimo-ui/hakimo/types';
import { BellSlashIcon } from '@heroicons/react/24/solid';
import ReverseProgress from '../../components/ReverseProgress';
import { EventHistory } from '../../types/event';
import { SNOOZE_TIME } from './utils';

interface Props {
  cameras: Camera[];
  unSnoozeCam: (camId: string) => void;
  camsEventHistoryMap: Record<string, EventHistory[]>;
}

export function SnoozedCams(props: Props) {
  const { cameras, unSnoozeCam, camsEventHistoryMap } = props;

  const onUnsooze = (camId: string) => () => unSnoozeCam(camId);

  const getLatestFrame = (camId: string) => {
    const camHistory = camsEventHistoryMap[camId];
    if (camHistory && camHistory.length > 0) {
      const lengthArr = camHistory.length;
      return camHistory[lengthArr - 1].frameUrl;
    } else {
      return '';
    }
  };

  return (
    <div className="dark:border-ondark-line-2 flex max-h-32 max-w-[80%] items-center gap-2 overflow-auto border p-2">
      <div className="text-sm">Snoozed Cams</div>
      <div className="flex gap-2 overflow-x-scroll">
        {cameras.map((cam) => (
          <div className="relative w-32 flex-shrink-0 rounded-md" key={cam.id}>
            <div className="pointer-events-none ">
              {getLatestFrame(cam.id) ? (
                <img
                  src={getLatestFrame(cam.id)}
                  alt="frame"
                  className="aspect-video"
                />
              ) : (
                cam.name
              )}
            </div>
            <div className="absolute top-1 right-1">
              <ReverseProgress
                duration={SNOOZE_TIME}
                size={15}
                strokeWidth={2}
              />
            </div>
            <button
              onClick={onUnsooze(cam.id)}
              className="bg-ondark-bg-2/60 pointer-events-auto absolute top-1 left-1 rounded-full"
            >
              <BellSlashIcon className="text-ondark-text-1 h-4 w-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
