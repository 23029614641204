import { Location } from '@hakimo-ui/hakimo/types';
import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { Tabs } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Floorplan } from './floorplan/Floorplan';
import { PointOfContact } from './location-contacts/PointOfContact';
import LocationDetailsHeader from './location-details-header/LocationDetailsHeader';
import SiteMonitorSchedule from './site-monitoring-schedule/SiteMonitorSchedule';
import SOPRoot from './sop/SOPRoot';
import { LocationDetailsTabs, viewItems } from './util';

interface Props {
  location: Location;
}

function LocationDetails(props: Props) {
  const { location } = props;
  const [activeViewId, setActiveViewId] = useState<LocationDetailsTabs>(
    viewItems[0].id
  );
  const onChangeTab = (tabId: LocationDetailsTabs) => setActiveViewId(tabId);
  const navigate = useNavigate();
  const onClickBack = () => {
    navigate('/locations');
  };

  return (
    <Page title="Location Details" onClickBack={onClickBack}>
      <div className="px-4">
        <LocationDetailsHeader location={location} />
        <Tabs
          items={viewItems}
          activeTabId={activeViewId}
          onChange={onChangeTab}
        />
        <div className="py-6">
          {activeViewId === LocationDetailsTabs.FLOOR_PLAN && (
            <Floorplan location={location} />
          )}
          {activeViewId === LocationDetailsTabs.SOP && (
            <SOPRoot
              locationId={location.id.toString()}
              tenantId={location.tenant_id ?? ''}
            />
          )}
          {activeViewId === LocationDetailsTabs.SITE_MONITORING_SCHEDULE && (
            <SiteMonitorSchedule location={location} />
          )}
          {activeViewId === LocationDetailsTabs.LOCATION_CONTACTS && (
            <PointOfContact
              locationId={location.id}
              tenantId={location.tenant_id || ''}
            />
          )}
        </div>
      </div>
    </Page>
  );
}

export default LocationDetails;
