import { useSOP } from '@hakimo-ui/hakimo/data-access';
import { SOPWorkflowView } from '@hakimo-ui/hakimo/feature-shared';
import { BasicPanel, QueryResult } from '@hakimo-ui/hakimo/ui-elements';

export interface Props {
  locationId: string;
}

export function AlarmDetailsSOP(props: Props) {
  const { locationId } = props;
  const queryResult = useSOP({
    locationId,
  });

  return (
    <BasicPanel title="Standard Operating Procedure">
      <QueryResult queryResult={queryResult} loadingText="Loading SOP...">
        {({ sop_workflow: sopWorkflow, id }) => (
          <div className="overflow-hidden">
            <SOPWorkflowView
              showTitle={false}
              sopId={id ?? ''}
              sopWorkflow={sopWorkflow}
            />
          </div>
        )}
      </QueryResult>
    </BasicPanel>
  );
}

export default AlarmDetailsSOP;
