import { useWebSocket } from '@hakimo-ui/hakimo/util';
import { useCallback, useEffect, useRef } from 'react';
import {
  VisionEvent,
  VisionEventType,
  VisionOutboundPayload,
} from '../types/event';

export function useVisionEvents(
  socketUrl: string,
  getAccessToken: () => Promise<string>,
  handleDetectionEvent: (event: VisionEvent[]) => void,
  handleHistoryEvent: (event: VisionEvent[]) => void,
  handleRebalanceEvent: () => void,
  onClose?: (event: CloseEvent) => void,
  onError?: (event: Event) => void
) {
  const detectionEventsRef = useRef<VisionEvent[]>([]);
  const historyEventsRef = useRef<VisionEvent[]>([]);

  useEffect(() => {
    // Update state every second to prevent too frequent updates
    const intervalId = window.setInterval(() => {
      handleDetectionEvent([...detectionEventsRef.current]);
      detectionEventsRef.current = [];
    }, 1000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [handleDetectionEvent]);

  useEffect(() => {
    // Update state every second to prevent too frequent updates
    const intervalId = window.setInterval(() => {
      handleHistoryEvent([...historyEventsRef.current]);
      historyEventsRef.current = [];
    }, 1020);

    return () => {
      window.clearInterval(intervalId);
    };
  }, [handleHistoryEvent]);

  const onWsEventReceive = useCallback((datum: MessageEvent<VisionEvent>) => {
    const data: VisionEvent = JSON.parse(datum.data as unknown as string);
    switch (data.event_type) {
      case VisionEventType.DETECTION:
        detectionEventsRef.current.push(data);
        break;
      case VisionEventType.HISTORY:
        historyEventsRef.current.push(data);
        break;
      case VisionEventType.REBALANCE:
        handleRebalanceEvent();
        break;
      default:
        break;
    }
  }, []);

  const { sendMessage } = useWebSocket<VisionEvent, VisionOutboundPayload>({
    url: socketUrl,
    onMessage: onWsEventReceive,
    getAccessToken,
    onClose,
    onError,
  });

  return { sendMessage };
}
