/* eslint-disable max-lines */
import { Camera } from '@hakimo-ui/hakimo/types';
import { trackEvent } from '@hakimo-ui/hakimo/util';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  CamAction,
  CameraData,
  CamGroupDetails,
  CountUpdate,
  EscalationState,
  GridConfig,
} from '../../types/common';
import { EventHistory } from '../../types/event';
import CamGroup from './cam-group/CamGroup';
import { calculateNewLayout, getNullRowsOrCols, removeColOrRow } from './utils';

export interface DynamicLayoutRef {
  addCameras: (cams: Camera[]) => void;
  getActiveCameras: () => string[];
  arrangeSmartly: () => void;
}

interface Props {
  onCellAction: (
    cameraId: string,
    groupId: string,
    actionType: CamAction,
    eventCamIds: string[]
  ) => void;
  camsEventHistoryMap: Record<string, EventHistory[]>;
  isFullScreen: boolean;
  onCameraCountUpdate: (countUpdate: CountUpdate) => void;
  gridConfig: GridConfig;
  camGroupDetailsMap: Record<string, CamGroupDetails>;
  escalationState: EscalationState;
  onResolveEscalation: (
    comment: string,
    groupId: string,
    eventCamIds: string[]
  ) => void;
  onCamShownInGrid: (camsData: CameraData[]) => void;
}

const DynamicLayout = forwardRef<DynamicLayoutRef, Props>((props, ref) => {
  const {
    onCellAction,
    camsEventHistoryMap,
    isFullScreen,
    onCameraCountUpdate,
    gridConfig,
    camGroupDetailsMap,
    escalationState,
    onResolveEscalation,
    onCamShownInGrid,
  } = props;
  const [layout, setLayout] = useState({ rows: 0, cols: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [gridGroupIds, setGridGroupIds] = useState<(string | null)[]>([]);
  const [queuedGroupIds, setQueuedGroupIds] = useState<string[]>([]);
  const [groupToCamsMap, setGroupToCamsMap] = useState<
    Map<string, Set<string>>
  >(new Map());
  const groupToCamsMapRef = useRef<Map<string, Set<string>>>(new Map());

  const updateSize = () => {
    if (containerRef.current) {
      setContainerSize({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
  };
  useEffect(() => {
    updateSize();
    containerRef.current && window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
    //  added escalationGroupId so update size is called when escalation screen opens and closes
  }, [escalationState.groupId, isFullScreen]);

  useEffect(() => {
    // Whenever a camera or group is added, removed from UI, send count update
    let camerasInGridCount = 0;
    let groupsInGridCount = 0;
    let camerasInQueueCount = 0;
    let groupInQueueCount = 0;

    groupToCamsMap.forEach((groupCams, groupId) => {
      if (gridGroupIds.includes(groupId)) {
        camerasInGridCount += groupCams.size;
        groupsInGridCount++;
      }
      if (queuedGroupIds.includes(groupId)) {
        camerasInQueueCount += groupCams.size;
        groupInQueueCount++;
      }
    });

    onCameraCountUpdate({
      camerasInGridCount,
      groupsInGridCount,
      camerasInQueueCount,
      groupInQueueCount,
    });
  }, [gridGroupIds, groupToCamsMap, onCameraCountUpdate, queuedGroupIds]);

  // If the limit is 16 cams,
  // 15 groups will be added to visible groups and rest will be added to queue
  const filterAndQueueGroups = useCallback(
    (
      visibleGroupsCount: number,
      newGroupIds: string[],
      maxCellCount: number
    ) => {
      let groupsToShow: string[] = [];
      let groupsToQueue: string[] = [];
      const diff = maxCellCount - (visibleGroupsCount + newGroupIds.length);
      if (diff > 0) {
        groupsToShow = newGroupIds;
      } else {
        const countCamsToQueue =
          visibleGroupsCount + newGroupIds.length - maxCellCount + 1;
        groupsToShow = newGroupIds.slice(
          0,
          newGroupIds.length - countCamsToQueue
        );
        groupsToQueue = newGroupIds.slice(
          newGroupIds.length - countCamsToQueue
        );
      }

      // push to "queuedGroupIds" and add groupsToQueue in it
      if (groupsToQueue.length > 0) {
        const updatedQueueGroupIds = [...queuedGroupIds];
        for (const groupId of groupsToQueue) {
          if (!updatedQueueGroupIds.includes(groupId)) {
            updatedQueueGroupIds.push(groupId);
          }
        }
        setQueuedGroupIds(updatedQueueGroupIds);
      }

      return groupsToShow;
    },
    [queuedGroupIds]
  );

  const onGroupShownInGrid = useCallback(
    (groupIds: string[]) => {
      const newCams: CameraData[] = [];
      const groupToCams = groupToCamsMapRef.current;
      groupIds.forEach((groupId) => {
        const groupCams = [...(groupToCams.get(groupId) ?? [])];
        newCams.push(...groupCams.map((camId) => ({ camId, groupId })));
      });
      onCamShownInGrid(newCams);
    },
    [onCamShownInGrid]
  );

  const addGroupsInGrid = useCallback(
    (groupsToAddInGrid: string[]) => {
      onGroupShownInGrid(groupsToAddInGrid);
      const prevRows = layout.rows;
      const prevCols = layout.cols;
      const { rows: newRows, cols: newCols } = calculateNewLayout(
        gridGroupIds.filter(Boolean).length + groupsToAddInGrid.length,
        prevRows,
        prevCols
      );
      let updatedGridGroupIds = [...gridGroupIds];

      // Add new columns if necessary
      if (newCols > prevCols) {
        for (let i = 0; i < prevRows; i++) {
          for (let j = prevCols; j < newCols; j++) {
            updatedGridGroupIds.splice(i * newCols + j, 0, null);
          }
        }
      }

      // Add new rows if necessary
      if (newRows > prevRows) {
        const newRowsCount = newRows - prevRows;
        const newRowGroups = new Array(newRowsCount * newCols).fill(null);
        updatedGridGroupIds = [...updatedGridGroupIds, ...newRowGroups];
      }

      // Add new cameras to empty slots
      for (const groupId of groupsToAddInGrid) {
        const emptyIndex = updatedGridGroupIds.findIndex(
          (group) => group === null
        );
        if (emptyIndex !== -1) {
          updatedGridGroupIds[emptyIndex] = groupId;
        }
      }

      setLayout({ rows: newRows, cols: newCols });
      setGridGroupIds(updatedGridGroupIds);
    },
    [gridGroupIds, layout.cols, layout.rows, onGroupShownInGrid]
  );

  const addCameras = useCallback(
    (camsToAdd: Camera[]) => {
      try {
        const updatedGroupsToCamMap = new Map(groupToCamsMap);
        // add to existing group if cam group already exists.
        const newGroups: string[] = [];
        camsToAdd.forEach((cam) => {
          const camId = cam.id;
          const groupId = cam.cameraGroupId ?? camId;
          if (!updatedGroupsToCamMap.has(groupId)) {
            updatedGroupsToCamMap.set(groupId, new Set());
            newGroups.push(groupId);
          }
          if (!updatedGroupsToCamMap.get(groupId)?.has(camId)) {
            updatedGroupsToCamMap.get(groupId)?.add(camId);
            gridGroupIds.includes(groupId) &&
              onCamShownInGrid([{ camId, groupId }]);
          }
        });
        // If there is some issue, look into converting it into ref and updating the state every second
        setGroupToCamsMap(updatedGroupsToCamMap);
        groupToCamsMapRef.current = updatedGroupsToCamMap;
        // all news cams are adjusted in existing groups (either in cell or in pending)
        if (newGroups.length === 0) {
          return;
        }

        const groupsToAddInGrid = filterAndQueueGroups(
          gridGroupIds.filter(Boolean).length,
          newGroups,
          gridConfig.cols * gridConfig.rows
        );

        // all new groups are queued
        if (groupsToAddInGrid.length === 0) {
          return;
        }
        addGroupsInGrid(groupsToAddInGrid);
      } catch (error) {
        trackEvent('scan_error_adding_camera', {
          error: error,
          camsToAdd,
        });
        // console.error('Error adding cameras:', error);
      }
    },
    [
      addGroupsInGrid,
      filterAndQueueGroups,
      gridConfig.cols,
      gridConfig.rows,
      gridGroupIds,
      groupToCamsMap,
      onCamShownInGrid,
    ]
  );

  const removeGroupFromGrid = useCallback(
    (groupIdsToRemove: string[], isCleanup = true) => {
      let updatedGridGroupIds = [...gridGroupIds];
      for (let i = 0; i < updatedGridGroupIds.length; i++) {
        const currGroupId = updatedGridGroupIds[i];
        if (currGroupId && groupIdsToRemove.includes(currGroupId)) {
          updatedGridGroupIds[i] = null;
        }
      }

      const { nullRow: nullRowIndex, nullCol: nullColIndex } =
        getNullRowsOrCols(updatedGridGroupIds, layout.rows, layout.cols);
      let updatedRows = layout.rows;
      let updatedCols = layout.cols;

      if (nullRowIndex !== undefined) {
        updatedGridGroupIds = removeColOrRow(
          updatedGridGroupIds,
          layout.rows,
          layout.cols,
          nullRowIndex,
          true
        );
        updatedRows -= 1;
      }
      if (nullColIndex !== undefined) {
        updatedGridGroupIds = removeColOrRow(
          updatedGridGroupIds,
          layout.rows,
          layout.cols,
          nullColIndex,
          false
        );
        updatedCols -= 1;
      }

      const removeNullGroups = (groupsUpd: Array<string | null>) =>
        groupsUpd.filter(Boolean);

      if (updatedRows > gridConfig.rows) {
        updatedRows = gridConfig.rows;
        updatedGridGroupIds = removeNullGroups(updatedGridGroupIds);
      }
      if (updatedCols > gridConfig.cols) {
        updatedCols = gridConfig.cols;
        updatedGridGroupIds = removeNullGroups(updatedGridGroupIds);
      }

      setLayout({ rows: updatedRows, cols: updatedCols });
      setGridGroupIds(updatedGridGroupIds);

      updateSize();
      if (isCleanup) {
        // remove cams and group from groupsToRemove
        const updatedGroupToCamsMap = new Map(groupToCamsMap);
        groupIdsToRemove.forEach((groupId) =>
          updatedGroupToCamsMap.delete(groupId)
        );
        setGroupToCamsMap(updatedGroupToCamsMap);
        groupToCamsMapRef.current = updatedGroupToCamsMap;
      }
    },
    [
      gridConfig.cols,
      gridConfig.rows,
      gridGroupIds,
      groupToCamsMap,
      layout.cols,
      layout.rows,
    ]
  );

  useEffect(() => {
    /*
      This effect is used for setting the grid count throeshold in check.
      If there's one cell empty it will add
      If grid size is decreased, it will remove appropriate cells and add it to queue.
      If grid size is increased, it willl add cells and remove them from queue.
    */
    // Filter out any falsy cameras
    const visibleGroups: string[] = gridGroupIds.filter(Boolean) as string[];
    const maxCellCount = gridConfig.rows * gridConfig.cols;
    const cellThreshold = maxCellCount - 1;

    if (queuedGroupIds.length > 0 && visibleGroups.length < cellThreshold) {
      // Calculate how many cameras can be added
      const availableSlots = cellThreshold - visibleGroups.length;
      const groupsToAdd = queuedGroupIds.slice(0, availableSlots);
      const updatedQueuedGroups = queuedGroupIds.slice(availableSlots);
      if (groupsToAdd.length > 0) {
        addGroupsInGrid(groupsToAdd);
        setQueuedGroupIds(updatedQueuedGroups);
      }
    } else if (visibleGroups.length > cellThreshold) {
      // Calculate how many cameras need to be removed
      // This is called when grid config is changed from high to low.
      const excessGroupCount = visibleGroups.length - cellThreshold;
      const groupsToRemove = visibleGroups.slice(-excessGroupCount);
      if (groupsToRemove.length > 0) {
        removeGroupFromGrid(groupsToRemove, false);
        setQueuedGroupIds((prevQueued) => [...prevQueued, ...groupsToRemove]);
      }
    }
  }, [
    addGroupsInGrid,
    gridConfig.cols,
    gridConfig.rows,
    gridGroupIds,
    queuedGroupIds,
    removeGroupFromGrid,
  ]);

  const getActiveCameras = () => {
    const groupsToCams = groupToCamsMapRef.current;
    return Array.from(groupsToCams.values()).flatMap((set) => Array.from(set));
  };

  const arrangeSmartly = () => {
    const visibleGroups = [...gridGroupIds].filter(Boolean) as string[];
    // bundle them together based on tenant
    const bundledGroups = visibleGroups.reduce((acc, groupId) => {
      const tenantId = camGroupDetailsMap[groupId].tenantId;
      if (!acc[tenantId]) {
        acc[tenantId] = [];
      }
      acc[tenantId].push(groupId);
      return acc;
    }, {} as Record<string, string[]>);

    // Sort tenants by number of cameras (descending)
    const sortedTenants = Object.keys(bundledGroups).sort(
      (a, b) => bundledGroups[b].length - bundledGroups[a].length
    );

    // Calculate new layout
    const totalGroups = visibleGroups.length;
    const newCols = Math.ceil(Math.sqrt(totalGroups));
    const newRows = Math.ceil(totalGroups / newCols);

    // Create a 2D array to represent the new layout
    const newLayout: (string | null)[][] = Array(newRows)
      .fill(null)
      .map(() => Array(newCols).fill(null));

    let currentCol = 0;
    let row = 0;

    // Place cameras in the new layout
    for (const tenantId of sortedTenants) {
      const tenantGroups = bundledGroups[tenantId];
      while (tenantGroups.length > 0) {
        newLayout[row][currentCol] = tenantGroups.shift() || null;
        row++;
        if (row >= newRows) {
          row = 0;

          currentCol++;
          if (currentCol >= newCols) break;
        }
      }
      if (currentCol >= newCols) break;
    }

    // Flatten the 2D array and ensure it has newCols * newRows elements
    const arrangedGroups = newLayout.flat();
    while (arrangedGroups.length < newCols * newRows) {
      arrangedGroups.push(null);
    }

    // Update state
    setLayout({ rows: newRows, cols: newCols });
    setGridGroupIds(arrangedGroups);
  };

  useImperativeHandle(ref, () => ({
    addCameras,
    getActiveCameras,
    arrangeSmartly,
  }));

  const onGroupAction = (
    cameraId: string,
    groupId: string,
    actionType: CamAction
  ) => {
    const camGroupDetails = camGroupDetailsMap[groupId];
    switch (actionType) {
      case CamAction.SAFE:
        trackEvent('event_marked_safe', {
          groupId: groupId,
          tenantId: camGroupDetails.tenantId,
        });
        removeGroupFromGrid([groupId]);
        break;
      case CamAction.ESCALATE:
        trackEvent('event_escalated', {
          groupId: groupId,
          tenantId: camGroupDetails.tenantId,
        });
        break;
      case CamAction.SNOOZE:
        trackEvent('event_snoozed', {
          groupId: groupId,
          tenantId: camGroupDetails.tenantId,
        });
        removeGroupFromGrid([groupId]);
        setTimeout(() => updateSize(), 0);
        break;
      case CamAction.INVESTIGATE:
        trackEvent('event_investigate', {
          groupId: groupId,
          tenantId: camGroupDetails.tenantId,
        });
        break;
      default:
        break;
    }
    const eventCamIds = [...(groupToCamsMap.get(groupId) ?? [])];

    onCellAction(cameraId, groupId, actionType, eventCamIds);
  };

  const handleResolveEscalation = (groupId: string) => (comment?: string) => {
    removeGroupFromGrid([groupId]);
    const eventCamIds = [...(groupToCamsMap.get(groupId) ?? [])];
    comment && onResolveEscalation(comment, groupId, eventCamIds);
  };

  const calculatePosition = useCallback(
    (index: number) => {
      const rows = layout.rows;
      const cols = layout.cols;
      const videoAspectRatio = 16 / 9;

      let cellWidth = containerSize.width / cols;
      let cellHeight = containerSize.height / rows;
      if (cellWidth / cellHeight > videoAspectRatio) {
        cellWidth = cellHeight * videoAspectRatio;
      } else {
        cellHeight = cellWidth / videoAspectRatio;
      }

      // Calculate the total space available for gaps
      const totalHorizontalGapSpace = containerSize.width - cellWidth * cols;
      const totalVerticalGapSpace = containerSize.height - cellHeight * rows;

      // Calculate gap sizes
      const horizontalGap = totalHorizontalGapSpace / (cols + 1);
      const verticalGap = totalVerticalGapSpace / (rows + 1);

      // Calculate the position
      const row = Math.floor(index / cols);
      const col = index % cols;

      const left = horizontalGap + col * (cellWidth + horizontalGap);
      const top = verticalGap + row * (cellHeight + verticalGap);

      return {
        left: `${left}px`,
        top: `${top}px`,
        width: `${cellWidth}px`,
        height: `${cellHeight}px`,
      };
    },
    [layout.rows, layout.cols, containerSize]
  );

  const queueLength = queuedGroupIds.length;

  return (
    <div
      className={clsx('relative h-[calc(100%)] max-h-full flex-grow')}
      ref={containerRef}
    >
      <AnimatePresence mode="popLayout">
        {gridGroupIds.map((groupId, index) => {
          return groupId ? (
            <motion.div
              key={groupId || index}
              layout
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.4 }}
              className={clsx(
                'absolute flex flex-col overflow-hidden rounded-md p-[2px]'
              )}
              style={{
                ...calculatePosition(index),
              }}
            >
              <CamGroup
                eventCamsSet={groupToCamsMap.get(groupId)}
                camGroupDetails={camGroupDetailsMap[groupId]}
                camsEventHistoryMap={camsEventHistoryMap}
                groupId={groupId}
                onGroupAction={onGroupAction}
                escalationState={escalationState}
                onResolveEscalation={handleResolveEscalation(groupId)}
              />
            </motion.div>
          ) : null;
        })}
        {queueLength > 0 && (
          <div
            style={{
              ...calculatePosition(gridConfig.cols * gridConfig.rows - 1),
            }}
            className={clsx(
              'dark:border-ondark-line-2 absolute flex items-center justify-center rounded-md border font-bold',
              queueLength < 5 && 'text-green-500',
              queueLength >= 5 && queueLength < 10 && 'text-orange-500',
              queueLength >= 10 && 'text-red-500'
            )}
          >
            + {queueLength} Groups
          </div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default DynamicLayout;
