/* eslint-disable max-lines */
import { WebrtcPlayer } from '@hakimo-ui/hakimo/ui-elements';
import { useFullscreen } from '@hakimo-ui/hakimo/util';
import clsx from 'clsx';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { CamFeedMode, FrameData } from '../../../types/common';
import { EventHistory } from '../../../types/event';
import CamControls from './CamControls';
import CamFeedActions from './CamFeedActions';
import CamPlaybackFeed from './CamPlaybackFeed';
import FramePlayer from './FramePlayer';

type Props = {
  name: string;
  tenantName: string;
  cameraTimeZone: string;
  livestreamUrl: string;
  onClickSafe?: () => void;
  onClickSnooze?: () => void;
  onClickInvestigate?: () => void;
  playbackUrl?: string;
  eventHistory?: EventHistory[];
  onDoubleClick?: () => void;
  globalCamsState?: {
    mode: CamFeedMode;
    playbackParams: string;
  };
  preloadLiveFeed?: boolean;
  elaborateFrames?: boolean;
};

const CamFeed = (props: Props) => {
  const {
    name,
    livestreamUrl,
    tenantName,
    cameraTimeZone,
    onClickSafe,
    onClickSnooze,
    onClickInvestigate,
    eventHistory = [],
    playbackUrl,
    onDoubleClick,
    globalCamsState,
    preloadLiveFeed = false,
    elaborateFrames = false,
  } = props;
  const [mode, setMode] = useState<CamFeedMode>(CamFeedMode.EVENT_VIDEO);
  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [playbackVideoSrc, setPlaybackVideoSrc] = useState<string>();
  const [isHovered, setIsHovered] = useState(false);
  const [currentFrameIndex, setCurrentFrameIndex] = useState(0);

  const { isFullScreen, toggleFullScreen } = useFullscreen(containerRef);

  useEffect(() => {
    if (globalCamsState) {
      setMode(globalCamsState.mode);
      setPlaybackVideoSrc(`${playbackUrl}&${globalCamsState.playbackParams}`);
    }
  }, [globalCamsState, playbackUrl]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        const entry = entries[0];
        const parent = entry.target;
        setSize({
          width: parent ? parent.clientWidth - 4 : 0,
          height: parent ? parent.clientHeight - 4 : 0,
        });
      }
    });

    if (containerRef.current) {
      const parentDiv = containerRef.current.parentElement;
      parentDiv && resizeObserver.observe(parentDiv);
    }

    return () => {
      if (containerRef.current) {
        const parentDiv = containerRef.current.parentElement;
        parentDiv && resizeObserver.unobserve(parentDiv);
      }
    };
  }, []);

  const hideActionText =
    containerRef.current && containerRef.current.clientWidth < 280;

  const onUpdatePlaybackSrc = (params: string) => {
    setPlaybackVideoSrc(`${playbackUrl}&${params}`);
    setMode(CamFeedMode.PLAYBACK);
  };
  const handleDoubleClick = () => onDoubleClick?.();
  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);

  const frames: Array<FrameData> = useMemo(() => {
    return eventHistory
      .sort((a, b) => a.createdTime - b.createdTime)
      .map((event) => ({
        id: event.eventId,
        frameUrl: event.frameUrl,
        createdTime: event.createdTime,
      }));
  }, [eventHistory]);

  const onFrameChange = (idx: number) => setCurrentFrameIndex(idx);

  return (
    <div
      className={clsx(
        'group/feed bg-ondark-bg-2 relative max-h-full max-w-full flex-grow',
        isFullScreen && ''
      )}
      ref={containerRef}
      onDoubleClick={handleDoubleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        width: size.width,
        height: size.height,
      }}
    >
      {preloadLiveFeed ? (
        <div
          className={clsx(
            'h-full',
            mode === CamFeedMode.LIVE ? 'block' : 'invisible h-0 w-0 opacity-0'
          )}
        >
          <WebrtcPlayer url={livestreamUrl} showControls={false} />
        </div>
      ) : (
        mode === CamFeedMode.LIVE && (
          <div className="h-full">
            <WebrtcPlayer url={livestreamUrl} showControls={false} />
          </div>
        )
      )}
      {mode === CamFeedMode.PLAYBACK && playbackVideoSrc && (
        <div className="h-full">
          <CamPlaybackFeed
            onUpdatePlaybackSrc={onUpdatePlaybackSrc}
            videoSrc={playbackVideoSrc}
          />
        </div>
      )}
      {mode === CamFeedMode.EVENT_VIDEO && (
        <FramePlayer
          frames={frames}
          currentFrameIndex={currentFrameIndex}
          onFrameChange={onFrameChange}
          elaborateFrames={elaborateFrames}
          timeZone={cameraTimeZone}
        />
      )}
      <CamControls
        eventHistory={eventHistory}
        currentFrameId={frames[currentFrameIndex]?.id}
        camFeedMode={mode}
        onChangeCamFeedMode={setMode}
        camName={name}
        onUpdatePlaybackSrc={onUpdatePlaybackSrc}
        tenantName={tenantName}
        isFullScreen={isFullScreen}
        toggleFullscreen={toggleFullScreen}
        showSeeker={isHovered}
        elaborateLiveMode={elaborateFrames}
        cameraTimeZone={cameraTimeZone}
      />

      <CamFeedActions
        hideActionText={!!hideActionText}
        onClickInvestigate={onClickInvestigate}
        onClickSafe={onClickSafe}
        onClickSnooze={onClickSnooze}
      />
    </div>
  );
};

export default CamFeed;
