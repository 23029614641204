import { Camera } from '@hakimo-ui/hakimo/types';
import { VisionEvent } from './event';

export enum ScanMode {
  MONITORING = 'monitoroing',
  ESCALATION = 'escalation',
}

export enum CamAction {
  SNOOZE = 'snooze',
  SAFE = 'safe',
  ESCALATE = 'escalate',
  INVESTIGATE = 'investigate',
}

export interface GridConfig {
  rows: number;
  cols: number;
}

export interface EscalationState {
  escalationId: string;
  locationId: string;
  groupId: string;
  cameraId: string;
  tenantId: string;
  createdTimestamp: number;
}

export interface CountUpdate {
  camerasInGridCount: number;
  groupsInGridCount: number;
  camerasInQueueCount: number;
  groupInQueueCount: number;
}

export interface CamGroupDetails {
  cameras: Camera[];
  tenantId: string;
  groupName: string;
}

export interface CamLifecycleState {
  enqueuedAt: number;
  shownInGridAt: number; // Timestamp when the camera was shown in the grid
  lastDetectionEvent?: VisionEvent;
}

export interface CameraData {
  camId: string;
  groupId: string;
}

export enum CamFeedMode {
  LIVE = 'live',
  PLAYBACK = 'playback',
  EVENT_VIDEO = 'event_video',
}

export interface FrameData {
  id: string;
  frameUrl: string;
  createdTime: number;
}
