export type SSEDatum = {
  id: string;
  p: number;
};

export interface EventHistory {
  id: string;
  eventId: string;
  cameraId: string;
  cameraName: string;
  createdTime: number; // in milliseconds
  frameUrl: string; //url
  personIds: string[];
}

export interface Person {
  name: string;
  class: number;
  confidence: number;
  box: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  person_id: string;
  overlap: number;
}

export enum VisionEventType {
  DETECTION = 'detection',
  HISTORY = 'history',
  REBALANCE = 'rebalance',
}

export interface VisionEventMetadata {
  persons: Person[];
  frames?: [string, number][];
}

export interface VisionEvent {
  id: string;
  event_id: string;
  camera_id: string;
  tenant_id: string;
  group_id: string;
  objects: { person: number };
  timestamp_utc: number;
  p: number;
  camera_name: string;
  metadata: VisionEventMetadata;
  event_type: VisionEventType;
}

export interface RebalanceEvent {
  event_type: VisionEventType.REBALANCE;
  user: {
    id: string;
    assignedGroups: string[];
  };
}

export enum VisionOutboundType {
  SAFE = 'safe',
  ESCALATION_OPEN = 'escalation_open',
  ESCALATION_CLOSE = 'escalation_close',
  HISTORY = 'history',
  CAMERA_COUNT = 'camera_count',
  INVESTIGATE = 'investigate',
}
export interface VisionOutboundPayload {
  camera_id?: string;
  group_id?: string;
  tenant_id?: string;
  event_type: VisionOutboundType;
  person_ids?: string[];
  additional_data?: {
    // all timestamps in milliseconds
    camera_display_timestamp?: number;
    escalation_open_timestamp?: number;
    event_timestamp?: number;
    escalation_id?: string;
    active_camera_count?: number; // visible camera count
    active_group_count?: number; // visible group count
    queue_camera_count?: number;
    queue_group_count?: number;
    snoozed_camera_count?: number;
    camera_queue_size?: number;
    grid_rows?: number;
    grid_cols?: number;
    username?: string; // email
    resolution_status?: string;
    camera_enqueue_timestamp?: number;
    camera_id_to_event_id?: Array<[string, string]>;
  };
}
