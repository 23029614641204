/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FrameData } from '../../../types/common';
import { formatTime } from '../utils';

interface Props {
  frames: Array<FrameData>;
  currentFrameIndex: number;
  timeZone: string;
  width?: number | string;
  height?: number | string;
  className?: string;
  onFrameChange: (idx: number) => void;
  elaborateFrames?: boolean;
  pauseBeforeNextInSec?: number;
}

export function FramePlayer({
  frames,
  currentFrameIndex,
  timeZone,
  width = '100%',
  height = '100%',
  className = '',
  onFrameChange,
  elaborateFrames,
  pauseBeforeNextInSec = 0.3,
}: Props) {
  const imageRef = useRef<HTMLImageElement>(null);

  const frame = useMemo(
    () => (frames.length > 0 ? frames[currentFrameIndex] : undefined),
    [currentFrameIndex, frames]
  );

  const moveToNextFrame = useCallback(() => {
    let nextFrame = currentFrameIndex + 1;
    if (nextFrame >= frames.length) {
      nextFrame = 0;
    }
    setTimeout(
      (frameIndex) => {
        onFrameChange(frameIndex);
      },
      pauseBeforeNextInSec * 1000, // milliseconds
      nextFrame
    );
  }, [currentFrameIndex, frames.length, onFrameChange, pauseBeforeNextInSec]);

  useEffect(() => {
    if (imageRef.current?.complete) {
      moveToNextFrame();
    }
  }, [imageRef.current?.complete, moveToNextFrame]);

  const handleOnError = () => {
    moveToNextFrame();
  };

  return (
    <div
      className={`frame-player relative flex items-center justify-center ${className}`}
      style={{ width, height }}
    >
      {frame ? (
        <div className="h-full">
          {elaborateFrames && (
            <span className="bg-ondark-bg-2 text-ondark-text-1 absolute top-0 right-0 rounded text-xs">
              {formatTime(new Date(frame.createdTime), timeZone, true)}
            </span>
          )}
          <img
            ref={imageRef}
            src={frame.frameUrl}
            alt={`Frame ${currentFrameIndex + 1}`}
            style={{ objectFit: 'contain', height: '100%' }}
            onError={handleOnError}
          />
        </div>
      ) : (
        <p className="text-ondark-text-1 absolute inset-0 flex animate-pulse items-center justify-center text-xs">
          Events loading...
        </p>
      )}
    </div>
  );
}

export default FramePlayer;
