/* eslint-disable max-lines */
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import { Camera } from '@hakimo-ui/hakimo/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CamFeedMode } from '../../../types/common';
import { EventHistory } from '../../../types/event';
import { formatTime } from '../utils';
import CameraTimeline from './CameraTimeline';
import Seeker from './Seeker';
import { getHoverTranslate, getTruncatedText } from './utils';

interface Props {
  cameras: Camera[];
  camsEventHistoryMap: Record<string, EventHistory[]>;
  updatePlaybackSrc: (params: string) => void;
  mode: CamFeedMode;
}

export function MultiCameraTimeline(props: Props) {
  const { cameras, camsEventHistoryMap, updatePlaybackSrc, mode } = props;
  const timelineRef = useRef<HTMLDivElement>(null);
  const timelineLabelRef = useRef<HTMLDivElement>(null);
  const [hoverPosition, setHoverPosition] = useState<number | null>(null);
  const [seekerPosition, setSeekerPosition] = useState<number | null>(null);

  const locationTimeZone = useMemo(() => {
    if (cameras.length > 0) {
      return cameras[0].location.timezone ?? 'UTC';
    } else {
      return 'UTC';
    }
  }, [cameras]);

  useEffect(() => {
    if (mode !== CamFeedMode.PLAYBACK) {
      setSeekerPosition(null);
    }
  }, [mode]);

  const tenMinutesAgo = new Date(new Date().getTime() - 10 * 60 * 1000);

  const getHoverTime = (position: number) => {
    if (position === null) return null;
    const hoverTime = new Date(
      tenMinutesAgo.getTime() + (position / 100) * 10 * 60 * 1000
    );
    return formatTime(hoverTime, locationTimeZone);
  };

  const onHoverTimeline = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentage = (x / rect.width) * 100;
      setHoverPosition(percentage);
    }
  };
  const onMouseLeave = () => setHoverPosition(null);

  const calculateAndUpdatePlaybackSrc = (position: number) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const percentage = (position / rect.width) * 100;
      const hoverTime = new Date(
        tenMinutesAgo.getTime() + (percentage / 100) * 10 * 60 * 1000
      );
      const hoverTimeISOFormat = hoverTime.toISOString();
      const duration = Math.floor((Date.now() - hoverTime.getTime()) / 1000);

      const params = new URLSearchParams();
      params.append('start', hoverTimeISOFormat);
      params.append('duration', String(duration));

      updatePlaybackSrc(params.toString());
    }
  };

  const onClickTimeline: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (timelineRef.current) {
      const rect = timelineRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      calculateAndUpdatePlaybackSrc(x);
      setSeekerPosition(x);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    setSeekerPosition((prev) => {
      const newPosition =
        (prev ?? timelineRef.current?.clientWidth ?? 0) + event.delta.x;
      calculateAndUpdatePlaybackSrc(newPosition);
      return newPosition;
    });
  };

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToParentElement, restrictToHorizontalAxis]}
    >
      <div className="flex items-center gap-2 pt-4">
        <div className="flex w-1/6 flex-col gap-1" ref={timelineLabelRef}>
          {cameras.map((cam) => (
            <span
              title={cam.name}
              key={cam.id}
              className="h-4 overflow-hidden overflow-ellipsis whitespace-nowrap text-xs"
            >
              {getTruncatedText(cam.name, timelineLabelRef)}
            </span>
          ))}
        </div>
        <div
          className="relative flex-1"
          ref={timelineRef}
          onMouseOverCapture={onHoverTimeline}
          onMouseMoveCapture={onHoverTimeline}
          onMouseLeave={onMouseLeave}
          onClick={onClickTimeline}
        >
          <Seeker
            seekerPosition={seekerPosition}
            containerHeight={timelineRef.current?.clientHeight ?? 0}
            containerWidth={timelineRef.current?.clientWidth ?? 0}
          />
          {hoverPosition && (
            <div
              className="bg-ondark-bg-3/70 absolute cursor-pointer rounded px-2 text-[9px] text-white"
              style={{
                left: `${hoverPosition}%`,
                transform: `translateX(-${getHoverTranslate(
                  hoverPosition,
                  timelineRef
                )})`,
                top: '-24px',
              }}
            >
              {getHoverTime(hoverPosition)}
            </div>
          )}
          <div className="flex flex-col gap-1">
            {cameras.map((cam) => (
              <div key={cam.id} className="relative h-4">
                <CameraTimeline
                  eventHistory={camsEventHistoryMap[cam.id]}
                  theme="system"
                  timezone={locationTimeZone}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </DndContext>
  );
}

export default MultiCameraTimeline;
