/* eslint-disable max-lines */
import { CamLifecycleState } from '../../types/common';
import { EventHistory, VisionEventMetadata } from '../../types/event';

export const SNOOZE_TIME = 60; // in seconds

export const TIMELINE_HEIGHT = 12;
export const HOVER_MARGIN_TOP = 24;

export const calculateNewLayout = (
  groupCount: number,
  rows: number,
  cols: number
) => {
  while (rows * cols < groupCount) {
    if (cols > rows) {
      rows++;
    } else {
      cols++;
    }
  }
  return { rows, cols };
};

export const getNullRowsOrCols = (
  items: (string | null)[],
  rows: number,
  cols: number
) => {
  const nullRows = [];
  const nullCols = [];

  // Check each row to see if all items are null
  for (let row = 0; row < rows; row++) {
    const start = row * cols;
    const end = start + cols;
    const rowItems = items.slice(start, end);

    if (rowItems.every((item) => item === null)) {
      nullRows.push(row);
    }
  }

  // Check each column to see if all items are null
  for (let col = 0; col < cols; col++) {
    let colAllNull = true;

    for (let row = 0; row < rows; row++) {
      const index = row * cols + col;

      if (items[index] !== null) {
        colAllNull = false;
        break;
      }
    }

    if (colAllNull) {
      nullCols.push(col);
    }
  }
  return {
    nullRow: nullRows.length > 0 ? nullRows[0] : undefined,
    nullCol: nullCols.length > 0 ? nullCols[0] : undefined,
  };
};

export const removeColOrRow = (
  items: (string | null)[],
  rows: number,
  cols: number,
  index: number,
  isRow = true
) => {
  const newItems = [...items];

  if (isRow) {
    // Remove all items from the specified row
    const start = index * cols;
    newItems.splice(start, cols);
  } else {
    // Remove all items from the specified column
    for (let row = rows - 1; row >= 0; row--) {
      const itemIndex = row * cols + index;
      newItems.splice(itemIndex, 1);
    }
  }

  return newItems;
};

export const getTenantColor = (tenantId: string) => {
  const colors = [
    '#40C4FF', // Bright light blue
    '#81C784', // Brighter green
    '#4DD0E1', // Brighter cyan
    '#FF8A65', // Brighter coral
    '#FFE57F', // Bright amber
    '#FF8A65', // Brighter orange
    '#FF80AB', // Brighter pink
    '#FFD54F', // Bright yellow
    '#4DB6AC', // Brighter teal
  ];
  const hash = tenantId
    .split('')
    .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
  return colors[hash % colors.length];
};

export const getPersonIds = (eventMetadata: VisionEventMetadata) => {
  if (
    !eventMetadata ||
    !eventMetadata.persons ||
    eventMetadata.persons.length === 0
  ) {
    return [];
  }
  return eventMetadata.persons.map((p) => p.person_id);
};

export const formatTime = (
  date: Date,
  timeZone: string,
  showTimezoneName?: boolean
) => {
  return date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
    timeZone: timeZone,
    timeZoneName: showTimezoneName ? 'short' : undefined,
  });
};

export const getCamToEventMapping = (
  eventCamIds: string[],
  camToLifecycleState: Record<string, CamLifecycleState>
): Array<[string, string]> => {
  return eventCamIds.map((camId) => {
    const camToId: [string, string] = [camId, ''];
    const camLifeCyleState = camToLifecycleState[camId];
    const camLastDetectionEvent = camLifeCyleState?.lastDetectionEvent;
    if (camLastDetectionEvent) {
      camToId[1] = camLastDetectionEvent.event_id;
    }
    return camToId;
  });
};

export const getPersonIdsFromEventHistory = (
  eventHistory: EventHistory[]
): string[] => {
  if (!eventHistory) {
    return [];
  }
  const allPersonIds = new Set<string>();
  eventHistory.forEach((event) => {
    event.personIds.forEach((personId) => allPersonIds.add(personId));
  });
  return [...allPersonIds];
};
